import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user.service';
import { WalletConnectService } from '../../services/wallet-connect.service';
import { Button, SubscriptionTypeData } from '../../interfaces';
import { BUTTON_SIZE, BUTTON_TYPE, COLOSSEUM_TIERS, EXCHANGE_CURRENCY } from '../../enums';
import { ButtonComponent } from '../button/button.component';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { LazyLoadDirective } from '../../directives/lazy-load.directive';
import { AuthStateService } from '../../services/auth/auth-state.service';
import { MatMenuModule } from '@angular/material/menu';
import { SmartWalletService } from 'src/app/pages/smart-wallet/smart-wallet.service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss'],
  imports: [ButtonComponent, CommonModule, ClickOutsideDirective, LazyLoadDirective, MatMenuModule, RouterLink],
  standalone: true
})
export class HeaderProfileComponent implements OnInit {
  @Input() isHomePage: boolean = false;

  protected readonly COLOSSEUM_TIERS = COLOSSEUM_TIERS;
  protected readonly EXCHANGE_CURRENCY = EXCHANGE_CURRENCY;

  userSubscriptionData: SubscriptionTypeData = {} as SubscriptionTypeData;
  isDropDownOpen: boolean = false;

  readonly becomeMemberBtn: Button = {
    name: 'Become a member',
    size: BUTTON_SIZE.MD
  };

  readonly goToColosseumBtn: Button = {
    name: 'The Colosseum',
    size: BUTTON_SIZE.MD
  };

  readonly signInBtn: Button = {
    name: 'Sign in',
    size: BUTTON_SIZE.MD,
    type: BUTTON_TYPE.DARK
  };

  get currencies(): EXCHANGE_CURRENCY[] {
    return Object.values(EXCHANGE_CURRENCY);
  }

  public isLoggedIn$ = this.authStateService.isLoggedIn$;
  public isMobile$ = this.breakpointObserver.observe(['(max-width: 768px)']).pipe(map((state) => state.matches));

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private authStateService: AuthStateService,
    public authService: AuthService,
    public walletConnectService: WalletConnectService,
    public userService: UserService,
    private breakpointObserver: BreakpointObserver,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.userService.userSubscriptionData$.subscribe({
      next: (response) => {
        this.userSubscriptionData = response;
      }
    });
  }

  openDropDown() {
    this.isDropDownOpen = !this.isDropDownOpen;
  }

  closeDropDown() {
    this.isDropDownOpen = false;
  }

  openProfile() {
    this.closeDropDown();
    if (this.userService.isAccountActive()) {
      this.router.navigateByUrl('profile');
    } else {
      this.toastrService.warning('Please verify your email to access The Colosseum!');
    }
  }

  becomeMember() {
    if (this.userService.isAccountActive()) {
      this.authService.acquireAccount(true, COLOSSEUM_TIERS.SILVER);
    } else {
      this.toastrService.warning('Please verify your email to access The Colosseum!');
    }
  }

  logout() {
    this.closeDropDown();
    this.walletConnectService.disconnectFromWallet();
    this.authService.logout();

    const smartWalletService = this.injector.get(SmartWalletService, null);

    if (smartWalletService) {
      smartWalletService.logout();
    }
  }

  goToColosseum() {
    this.router.navigateByUrl('content');
  }

  openRoot() {
    this.router.navigateByUrl('/');
  }

  signIn() {
    this.authService.login();
  }
}
